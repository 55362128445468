import React from 'react'
import { Block, H1, P } from '@ebinion/zeke-design-system'

import Layout from '../components/Layout'
import CaseStudies from '../components/CaseStudies'

export default function NotFoundPage() {
  return (
    <Layout title="404: Page Not found">
      <Block isCentered constrain="text" padding="xl">
        <H1>Page Not Found</H1>
        <P>
          Sorry, the page you were looking for doesn&rsquo;t exist. Perhaps you were looking for one
          of the case studies below?
        </P>
      </Block>
      <CaseStudies />
    </Layout>
  )
}
