import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { A, Block, H2, H4, Matrix, Text } from '@ebinion/zeke-design-system'

import Rounded from './Rounded'

export default function CaseStudies({ currentID }) {
  const data = useStaticQuery(graphql`
    query {
      entries: allContentfulPortfolioEntry(
        limit: 4
        sort: { fields: year, order: DESC }
        filter: { isPublished: { eq: true } }
      ) {
        nodes {
          id
          slug
          title
          year
          type
          previewImage {
            description
            gatsbyImageData(
              height: 600
              width: 800
              resizingBehavior: FILL
              cropFocus: TOP
              placeholder: BLURRED
            )
            title
          }
        }
      }
    }
  `)

  const entriesWithoutCurrent = () => {
    const {
      entries: { nodes },
    } = data

    let newNodes

    if (currentID) {
      newNodes = nodes.filter(element => element.id !== currentID)
    } else {
      newNodes = nodes
    }

    return newNodes.length > 3 ? newNodes.slice(0, 3) : newNodes
  }

  return (
    <Block as="aside" color="dark" padding="none">
      <Block padding="xl" constrain="text" isCentered>
        <Text align="center" color="gold" bold noMargin>
          Case Studies
        </Text>
        <H2 color="knockout" align="center" noMargin>
          More examples of web and mobile projects
        </H2>
      </Block>
      <Block constrain="site" padding="xl" paddingTop="0" isCentered>
        <Matrix
          gutter="xl"
          maxColumns={entriesWithoutCurrent().length < 3 ? entriesWithoutCurrent().length : 3}
        >
          {entriesWithoutCurrent().map(({ id, slug, title, type, previewImage }) => (
            <A as={Link} to={`/${slug}`} key={`preview-${id}`} kind="incognito">
              {previewImage && (
                <Rounded>
                  <GatsbyImage
                    image={previewImage.gatsbyImageData}
                    alt={previewImage.description || previewImage.title || ''}
                  />
                </Rounded>
              )}
              <Text color="gold" noMargin respondToLinkHover>
                {type}
              </Text>
              <H4 as="h3" color="knockout" noMargin respondToLinkHover>
                {title}
              </H4>
            </A>
          ))}
        </Matrix>
      </Block>
    </Block>
  )
}

CaseStudies.propTypes = {
  currentID: PropTypes.string.isRequired,
}
